import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'
import className from 'classnames'
import {useRouter} from 'next/router'
import dynamic from 'next/dynamic'

import {t} from '@lib/helpers'

const ImageLoader = dynamic(() => import('@components/ImageLoader'))
const ContactForm = dynamic(() => import('@components/ContactForm'), {
  ssr: false,
})

const GetStarted = ({isRecaptchaValidated}) => {
  const router = useRouter()

  return (
    <React.Fragment>
      {get(router, 'pathname') != '/contact' && (
        <div className='GetStarted'>
          <ImageLoader klass='GetStarted__img' src='/images/GetStarted.jpg' />

          <div className='container'>
            <h2 className='GetStarted__h2'>{t('layout.GetStarted.h2')}</h2>

            <p className='GetStarted__p'>{t('layout.GetStarted.p')}</p>

            <ContactForm isRecaptchaValidated={isRecaptchaValidated} />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

GetStarted.propTypes = {}

export default GetStarted
